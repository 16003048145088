import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Heading } from '../components/heading'
import styled from 'styled-components'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <Section>
            <Heading size="large">Not found</Heading>
            <p>Page was not found.</p>
        </Section>
    </Layout>
)

const Section = styled.section`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`

export default NotFoundPage
